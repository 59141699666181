import React from "react"
import H2Header from "../components/H2Header"
import Layout from "../components/layout/Layout"
import { GridContainer, GridItemFullWidth } from "../components/layout/Grid"
import SEO from "../components/Seo"

const PrivacyPolicy = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" />

      <GridContainer>
        <GridItemFullWidth>
          <H2Header>Privacy Policy</H2Header>
        </GridItemFullWidth>
      </GridContainer>
    </Layout>
  )
}

export default PrivacyPolicy
